import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache, { StylisPlugin } from "@emotion/cache";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@mui/styles";
import { ChildrenProp } from "@/types/components";

// Configure JSS
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});

interface RTLProps extends ChildrenProp {
  dir: "rtl" | "ltr";
}

const RTL = ({ children, dir }: RTLProps) => {
  // Create rtl cache
  const cacheRtl = createCache({
    key: dir === "rtl" ? "css-rtl" : "css-ltr",
    stylisPlugins: dir === "rtl" ? ([rtlPlugin] as [StylisPlugin]) : undefined,
    prepend: true,
  });

  return (
    // @ts-ignore
    <CacheProvider value={cacheRtl}>
      {/* @ts-ignore */}
      <StylesProvider jss={jss}>{children}</StylesProvider>
    </CacheProvider>
  );
};

export default RTL;
