import { getStorageItem } from "@/helpers/general-helpers";

export const API_END_POINT = process.env.NEXT_PUBLIC_KIOSK_API_ENDPOINT;
export const KIOSK_ACCESS_KEY = process.env.NEXT_PUBLIC_KIOSK_ACCESS_KEY;
export const KIOSK_SETTINGS_API = process.env.NEXT_PUBLIC_KIOSK_SETTINGS;
export const KIOSK_GENERAL_DOMAIN =
  process.env.NEXT_PUBLIC_KIOSK_WHITELABEL_SETTINGS;
export const KIOSK_DISABLE_PRINTING_EVENTS_IDS =
  process.env.NEXT_PUBLIC_KIOSK_DISABLE_PRINTING_EVENTS_IDS;
export const KIOSK_SKIP_CHECK_IN_PAGE_EVENTS_IDS =
  process.env.NEXT_PUBLIC_KIOSK_SKIP_CHECK_IN_PAGE_EVENTS_IDS;

export const STORAGE_KEYS = Object.freeze({
  email: "kiosk-email",
  password: "kiosk-password",
  access_key: "kiosk-access-key",
  timeZone: "kiosk-time-zone",
  currentLanguage: "kiosk-current-language",
  eventName: "kiosk-event-name",
  baseUrl: "kiosk-base-url",
});

export const VALIDATION_REGEX = Object.freeze({
  email: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
});

export const BADGE_IMAGE_URL =
  "https://barcode1.co.uk/wp-content/uploads/qrcodeuk.gif";

export enum SCANNING_RESPONSE_STATUS {
  CHECKED = "Checked",
  UNCHECKED = "Unchecked",
}

export const AVAILABLE_LANGUAGE = {
  AR: "ar",
  EN: "en",
} as const;

export const LANGUAGE_OPTIONS = [
  { label: "EN", value: AVAILABLE_LANGUAGE.EN },
  { label: "عربي", value: AVAILABLE_LANGUAGE.AR },
];

export const getApiEndpoint = () => {
  return getStorageItem(STORAGE_KEYS.baseUrl) || API_END_POINT;
};
