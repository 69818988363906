'use client'
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Navbar from "@/components/Navbar";
import { MainLayoutProps } from "@/types/components";
import { useStyles } from "./style";

const MainLayout = ({ children }: MainLayoutProps) => {
  const classes = useStyles();

  return (
    <Box height="100vh" color="#292B2C">
      <Navbar />
      <Container maxWidth="lg" className={classes.mainContentContainer}>
        {children}
      </Container>
    </Box>
  );
};

export default MainLayout;
