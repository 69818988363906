'use client'
import { useState, useEffect, useLayoutEffect, useMemo } from "react";
import { createTheme, ThemeProvider, Theme } from "@mui/material/styles";
import RTL from "@/components/RTL";
import { useGlobalContext } from "@/contexts/GlobalContext";
import { MaterialThemeProviderProps } from "@/types/components";
import { enUS, arEG } from "@mui/material/locale";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  // typography: {
  //   fontFamily: ["Poppins"].join(",")
  // },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#80C837",
      // dark: will be calculated from palette.primary.main,
      contrastText: "#fff", //will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: "#3783e7",
      // dark: will be calculated from palette.primary.main,
      // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
    },
    error: {
      // light: will be calculated from palette.primary.main,
      main: "#F20A34",
      // dark: will be calculated from palette.primary.main,
      // contrastText: "#fff" //will be calculated to contrast with palette.primary.main
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        * {
          margin: 0;
        }
        body {
          overflow-x: hidden;
        }
      `,
    },
    // MuiButton: {
    //   defaultProps: {
    //     disableRipple: true,
    //   },
    // },
  },
});

const MaterialThemeProvider = ({ children,lng,dir= "ltr"}: MaterialThemeProviderProps) => {
  
  // const { i18n } = useTranslation();
  const [{ configuration }] = useGlobalContext();





  const updatedTheme = useMemo(
    () =>
      createTheme(
        {
          ...theme,
          palette: {
            primary: {
              main: configuration?.color
                ? configuration.color
                : theme.palette.primary.main,
            },
          },
          direction: dir,
        },
        dir === "rtl" ? arEG : enUS
      ),
    [dir, configuration?.color]
  );

  return (
    <ThemeProvider theme={updatedTheme}>
      <RTL dir={dir}>{children}</RTL>
    </ThemeProvider>
  );
};

export default MaterialThemeProvider;
