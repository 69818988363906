import { forwardRef } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { CustomSelectProps } from "@/types/components";

const CustomSelect = forwardRef(
  (
    {
      options,
      size = "small",
      label = "",
      labelId = "",
      placeholder = "",
      fullWidth = false,
      margin = "none",
      error = false,
      className,
      selectClass,
      required,
      helperText,
      ...rest
    }: CustomSelectProps,
    ref
  ) => {
    let selectProps = label ? { label: label, ...rest } : { ...rest };

    return (
      <FormControl
        size={size}
        margin={margin}
        fullWidth={fullWidth}
        error={error}
        className={className}
        required={required}
      >
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          ref={ref}
          labelId={labelId}
          className={selectClass}
          {...selectProps}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    );
  }
);

export default CustomSelect;
export type { SelectChangeEvent };
