"use client"
import { forwardRef } from "react";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { CustomButtonProps } from "@/types/components";

const CustomButton = forwardRef<HTMLButtonElement | null, CustomButtonProps>(
  (
    {
      children,
      variant = "contained",
      color = "primary",
      size,
      className,
      loading,
      ...rest
    },
    ref
  ) => {
    return loading ? (
      <LoadingButton
        loading
        variant={variant}
        color={color}
        size={size}
        className={className}
        ref={ref}
      >
        {children}
      </LoadingButton>
    ) : (
      <Button
        color={color}
        variant={variant}
        size={size}
        className={className}
        ref={ref}
        {...rest}
      >
        {children}
      </Button>
    );
  }
);

export default CustomButton;
