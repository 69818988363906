'use client'
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@/components/Button";
import Select, { SelectChangeEvent } from "@/components/Select";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useGlobalContext } from "@/contexts/GlobalContext";
import {useTranslation} from "@/i18n/client";
import {
  removeStorageItem,
  setStorageItem,
  getStorageItem,
} from "@/helpers/general-helpers";
import {
  STORAGE_KEYS,
  LANGUAGE_OPTIONS,
  AVAILABLE_LANGUAGE,
} from "@/config/kiosk";
import { useParams ,useRouter,usePathname} from "next/navigation";

const Navbar = () => {
  const [, setGlobalState] = useGlobalContext();
  const { lng } = useParams();
  const { t:translate  } = useTranslation(lng,"navbar");
  const { push, } = useRouter();
  const pathname = usePathname();


  const logout = () => {
    removeStorageItem(STORAGE_KEYS.email);
    removeStorageItem(STORAGE_KEYS.password);
    removeStorageItem(STORAGE_KEYS.access_key);
    removeStorageItem(STORAGE_KEYS.timeZone);
    removeStorageItem(STORAGE_KEYS.eventName);
    removeStorageItem(STORAGE_KEYS.baseUrl);
    setGlobalState((prevState) => ({ ...prevState, isAuth: false }));
    push(`/${lng}/sign-in`);
  };

  const languageChangeHandler = (event: SelectChangeEvent<unknown>) => {
    setStorageItem(STORAGE_KEYS.currentLanguage, event.target.value as string);
    const currentPath = pathname.split("/").slice(2).join("/");
    push(`/${event.target.value}/${currentPath}`);
  };

  return (
    <Box padding="5px 25px">
      <Grid container justifyContent="space-between">
        <Button
          onClick={logout}
          variant="text"
          color="inherit"
          startIcon={<ExitToAppIcon />}
        >
          {translate("components.Navbar.logout")}
        </Button>
        <Select
          value={
            getStorageItem(STORAGE_KEYS.currentLanguage) ===
            AVAILABLE_LANGUAGE.AR
              ? AVAILABLE_LANGUAGE.AR
              : AVAILABLE_LANGUAGE.EN
          }
          onChange={languageChangeHandler}
          options={LANGUAGE_OPTIONS}
        />
      </Grid>
    </Box>
  );
};

export default Navbar;
