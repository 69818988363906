'use client'
import { createContext, useState, useContext, useEffect } from "react";
import { getStorageItem } from "@/helpers/general-helpers";
import { STORAGE_KEYS } from "@/config/kiosk";
import {
  GlobalContextType,
  GlobalStateType,
} from "@/types/contexts/globalContext";
import { ChildrenProp } from "@/types/components";
import { useRouter,usePathname } from "next/navigation";

import {ROUTES_URL }  from "@/helpers/router-helper";
const initialState: GlobalStateType = {
  isAuth: getStorageItem(STORAGE_KEYS.email) ? true : false,
  email: getStorageItem(STORAGE_KEYS.email) || "",
  password: getStorageItem(STORAGE_KEYS.password) || "",
  scannedBarcodeData: undefined,
  timeZone: getStorageItem(STORAGE_KEYS.timeZone) || "",
  configuration: undefined,
};

const GlobalContext = createContext<GlobalContextType>([
  initialState,
  () => {},
]);

export const useGlobalContext = () => useContext(GlobalContext);

const useGlobalState = () => useState(initialState);

const GlobalState = ({ children }: ChildrenProp) => {
  const [state, setState] = useGlobalState();
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    if (!state.isAuth && !pathname.includes( "/sign-in")) {
      router.push(`/sign-in`);
    }
    if (state.isAuth && pathname.includes( "/sign-in")) {
      router.push(`/events`);
    }
    if (pathname === "/") {
      router.push(`/events`);
    }



  }, [state.isAuth]);
  return (
    <GlobalContext.Provider value={useGlobalState()}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalState;
