"use client";
import { NextIntlClientProvider } from "next-intl";
import CssBaseline from "@mui/material/CssBaseline";
import MaterialThemeProvider from "@/Layout/MaterialThemeProvider";
import GlobalState from "@/contexts/GlobalContext";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


export function Providers({ children ,locale,messages}: { children: React.ReactNode ,
  locale : string,
  messages : any
}) {
  return (
    <>
      <NextIntlClientProvider locale={locale} messages={messages}>
      <GlobalState>
        <MaterialThemeProvider lng={locale} dir={
          locale === "ar" ? "rtl" : "ltr"
        }>
          <CssBaseline />
          <ToastContainer position={toast.POSITION.TOP_CENTER} />
        {children}
        </MaterialThemeProvider>
        </GlobalState>

      </NextIntlClientProvider>
    </>
  );
}
