import { FieldError } from "react-hook-form";
import { STORAGE_KEYS, AVAILABLE_LANGUAGE } from "@/config/kiosk";

export const setStorageItem = (item: string, value: any) => {
  localStorage.setItem(item, JSON.stringify(value));
};

export const getStorageItem = (item: string) => {
  if (typeof window !== 'undefined') {
    return JSON.parse(localStorage.getItem(item) as string);
  }
  return null;
};

export const removeStorageItem = (item: string) => {
  localStorage.removeItem(item);
};

export const getEmailErrorHelperText = (
  emailFieldError: FieldError | undefined
) => {
  let errorText =
    emailFieldError?.type === "required" ? emailFieldError?.message : "";
  if (emailFieldError?.type === "pattern") {
    errorText = emailFieldError?.message;
  }
  return errorText;
};

export const getPasswordErrorHelperText = (
  passwordFieldError: FieldError | undefined
) => {
  let errorText =
    passwordFieldError?.type === "required" ? passwordFieldError?.message : "";
  return errorText;
};

export const getInitialBodyDirection = () => {
  if (getStorageItem(STORAGE_KEYS.currentLanguage) === AVAILABLE_LANGUAGE.AR) {
    return "rtl";
  } else {
    return "ltr";
  }
};
